import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  watch
} from 'vue'
import {
  useRouter
} from 'vue-router'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import moment from 'moment'
import {
  transScheduleBusUseCase
} from '@/domain/usecase/transactions/scheduleBusUseCase'

export default {
  name: 'ScheduleBusForm',
  props: {
    data: Object,
    inAction: String
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const attProps = reactive(props)
    const typeTripOption = ref([])
    const typeBusOption = ref([])
    const priceOption = ref([])
    const ritOption = ref([{
      value: '1',
      label: 'RIT 1'
    }, {
      value: '2',
      label: 'RIT 2'
    }])

    const dataForm = reactive({
      scheduleCode: '',
      inactiveDate: '',
      activeDate: '',
      typeTrip: null,
      typeBus: null,
      rit: null,
      price: null,
      isActive: true,
      agentShow: true,
      keterangan: null
    })

    const Id = ref(null)

    const title = ref('Tambah Jadwal')
    const submitted = ref(false)
    let rules
    if (attProps.inAction === 'duplicateData') {
      rules = {
        scheduleCode: {
          required: helpers.withMessage('Kode Jadwal harus diisi', required)
        },
        activeDate: {
          required: helpers.withMessage('Tanggal Mulai harus diisi', required)
        },
        inactiveDate: {
          required: helpers.withMessage('Tanggal Berhenti harus diisi', required)
        },
        typeBus: {
          required: helpers.withMessage('Bagasi harus diisi', required)
        },
        rit: {
          required: helpers.withMessage('RIT harus diisi', required)
        },
      }
    } else {
      rules = {
        scheduleCode: {
          required: helpers.withMessage('Kode Jadwal harus diisi', required)
        },
        activeDate: {
          required: helpers.withMessage('Tanggal Aktif harus diisi', required)
        },
        typeBus: {
          required: helpers.withMessage('Bagasi harus diisi', required)
        },
        rit: {
          required: helpers.withMessage('RIT harus diisi', required)
        },
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }
    if (attProps.data) {
      if (attProps.inAction === 'duplicateData') {
        title.value = 'Duplikat Jadwal'
        dataForm.scheduleCode = `${attProps.data.KodeJadwal}_COPY`
      } else if (attProps.inAction === 'editData') {
        title.value = 'Edit Jadwal'
        dataForm.scheduleCode = `${attProps.data.KodeJadwal}`
      }
      Id.value = attProps.data.Id
      dataForm.inactiveDate = moment(attProps.data.TanggalNonAktif).format('DD-MM-YYYY')
      dataForm.activeDate = moment(attProps.data.TanggalAktif).format('DD-MM-YYYY')
      dataForm.typeBus = attProps.data.TipeBusId
      dataForm.rit = attProps.data.Rit
      dataForm.keterangan = attProps.data.Keterangan
      dataForm.isActive = attProps.data.IsActive
      dataForm.agentShow = attProps.data.IsShow
    }

    const checkResponse = async (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (attProps.data) {
          $toast.add({
            severity: 'success',
            summary: response.result.title ?? response.result.Title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
          if (attProps.inAction === 'addData') {
            router.push({
              name: 'transaksi-jadwal-bus-detail',
              params: {
                idJadwal: response.result.Id
              }
            })
          }
        }
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitProccess = async () => {
      let data
      let response
      let activeDate
      let inactiveDate
      if (dataForm.activeDate.toString().includes('-')) {
        const arrDate = dataForm.activeDate.split('-')
        activeDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T00:00:00+07:00`
      } else {
        activeDate = `${moment(dataForm.activeDate).format('YYYY-MM-DD')}T00:00:00+07:00`
      }
      if (dataForm.inactiveDate.toString().includes('-')) {
        const arrDate = dataForm.inactiveDate.split('-')
        inactiveDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T00:00:00+07:00`
      } else {
        inactiveDate = `${moment(dataForm.inactiveDate).format('YYYY-MM-DD')}T00:00:00+07:00`
      }
      if (attProps.inAction === 'duplicateData') {
        data = {
          FromId: Id.value,
          Jadwal: {
            KodeJadwal: dataForm.scheduleCode.toUpperCase(),
            Rit: dataForm.rit,
            TanggalAktif: activeDate,
            TanggalNonAktif: inactiveDate,
            IsActive: dataForm.isActive,
            IsShow: dataForm.agentShow,
            TipeBusId: dataForm.typeBus,
            Keterangan: dataForm.keterangan
          }
        }
        response = await transScheduleBusUseCase.duplicateData(data)
      } else {
        data = {
          KodeJadwal: dataForm.scheduleCode.toUpperCase(),
          Rit: dataForm.rit,
          TanggalAktif: activeDate,
          TanggalNonAktif: activeDate,
          IsActive: dataForm.isActive,
          IsShow: dataForm.agentShow,
          TipeBusId: dataForm.typeBus,
          Keterangan: dataForm.keterangan
        }
        response = await transScheduleBusUseCase.submitData(Id.value, data)
      }
      checkResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const getDataTipeBus = async () => {
      const response = await transScheduleBusUseCase.getTipeBus()
      typeBusOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: `${x.Kode} - ${x.Nama}`
      }))
    }

    onMounted(() => {
      getDataTipeBus()
    })
    return {
      attProps,
      dataForm,
      typeTripOption,
      typeBusOption,
      priceOption,
      app,
      title,
      submitted,
      rules,
      v$,
      ritOption,
      closeSidebar,
      submitData,
      moment,
      submitProccess,
      checkResponse,
      Id
    }
  }
}
