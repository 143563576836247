import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  defineComponent
} from 'vue'
import {
  transScheduleBusUseCase
} from '@/domain/usecase'
import moment from 'moment'
import ScheduleBusForm from '@/views/transactions/schedule-bus/ScheduleBusForm.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  name: 'CardHeaderScheduleBusDetail',
  components: {
    ScheduleBusForm
  },
  props: {
    idJadwal: null
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const sliderActive = ref(false)
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const op = ref()
    const store = useStore()
    const route = useRoute()

    const dataSource = ref({})
    const attProps = reactive(props)
    const dataForm = ref({})
    const formChangeJadwal = reactive({
      totalSend: 0 as any,
      keteranganCatatan: null
    })
    const inActionForm = ref('')
    const dialogCatatan = ref(false)
    const selectRadio = ref(false)
    const textCatatan = ref(null)
    const submitted = ref(false)
    const rules = {
      totalSend: {
        required: helpers.withMessage('Tambahkan jam harus diisi', required)
      },
      keteranganCatatan: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, formChangeJadwal)

    const getDetailSchedule = async () => {
      store.dispatch('showLoading')
      const response = await transScheduleBusUseCase.getDataForm(Number(attProps.idJadwal))
      dataSource.value = {
        KodeJadwal: response.result.KodeJadwal,
        TanggalNonAktif: response.result.TanggalNonAktif,
        TanggalAktif: response.result.TanggalAktif,
        Rit: response.result.Rit,
        KodeBus: response.result.TipeBus.Kode,
        TotalPendapatan: response.result.TotalPendapatan,
        IsActive: response.result.IsActive,
        Id: response.result.Id,
        IsShow: response.result.IsShow,
        Keterangan: response.result.Keterangan,
        TipeBusId: response.result.TipeBusId,
        Attribute1: response.result.Attribute1,
      }
      store.dispatch('hideLoading')
    }

    const showDropDown = (evt: any) => {
      dataForm.value = dataSource.value
      op.value.toggle(evt)
    }

    const editData = (val: any) => {
      inActionForm.value = 'editData'
      dataForm.value = val
      sliderActive.value = true
    }
    const copyData = (val: any) => {
      inActionForm.value = 'duplicateData'
      dataForm.value = val
      sliderActive.value = true
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const activated = async (val: any) => {
      store.dispatch('showLoading')
      const data = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await transScheduleBusUseCase.changeIsActive(data)
      // console.log('response active', response)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorChangeStatus,
          group: 'bc',
          life: 1500
        })
      } else {
        $toast.add({
          severity: 'success',
          summary: response.result.title ?? response.result.Title,
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          activated(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const selectProblem = ref([
      {
        value: true,
        title: 'Jangan Tampilkan Jadwal'
      }, {
        value: false,
        title: 'Tambah Waktu Jadwal'
      }])

    const tambahWaktu = async (isFormValid: any) => {
      let data
      submitted.value = true
      if (!isFormValid) {
        return
      }
      store.dispatch('showLoading')
      if (!selectRadio.value) {
        data = {
          JadwalHdId: Number(attProps.idJadwal),
          TambahanJam: parseFloat(formChangeJadwal.totalSend),
          TampilkanJadwal: null,
          Keterangan: formChangeJadwal.keteranganCatatan
        }
      } else {
        data = {
          JadwalHdId: Number(attProps.idJadwal),
          TambahanJam: 0,
          TampilkanJadwal: false,
          Keterangan: formChangeJadwal.keteranganCatatan
        }
      }
      const response = await transScheduleBusUseCase.addedTime(data)
      if (response.error) {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        dialogCatatan.value = false
        // console.log('response', response)
        store.dispatch('updateRute')
        getDetailSchedule()
        $toast.add({
          severity: 'success',
          summary: response.result.title ?? response.result.Title,
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
      }
    }

    onMounted(() => {
      // console.log('in att', attProps)
      getDetailSchedule()
    })
    return {
      route,
      dataSource,
      attProps,
      getDetailSchedule,
      moment,
      sliderActive,
      closeSidebar,
      op,
      dataForm,
      showDropDown,
      inActionForm,
      editData,
      copyData,
      store,
      changeIsActive,
      activated,
      dialogCatatan,
      selectProblem,
      selectRadio,
      textCatatan,
      tambahWaktu,
      v$,
      submitted,
      checkPermission,
      PermissionEnum
    }
  }
})
