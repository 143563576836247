import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "schedule-bus-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardHeaderScheduleBusDetail = _resolveComponent("CardHeaderScheduleBusDetail")
  const _component_DetailSchedule = _resolveComponent("DetailSchedule")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_CardHeaderScheduleBusDetail, {
      idJadwal: _ctx.getDecriptId()
    }, null, 8, ["idJadwal"]),
    _createVNode(_component_DetailSchedule, {
      idJadwal: _ctx.getDecriptId()
    }, null, 8, ["idJadwal"])
  ]))
}