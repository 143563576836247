import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  transScheduleDetailUseCase
} from '@/domain/usecase'
import moment from 'moment'
import {
  useStore
} from 'vuex'
import DetailScheduleForm from '@/views/transactions/schedule-bus/detail/detail-schedule/DetailScheduleForm.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'DetailSchedule',
  components: {
    DetailScheduleForm
  },
  props: {
    idJadwal: null
  },
  setup(props: any) {
    const inputSearch = ref('')
    const sendInputSearch = ref('')
    const sortingValue = ref('Agen/Nama asc')
    // const sortingValue = ref('WaktuKeberangkatan asc')
    const sliderActive = ref(false)
    const store = useStore()
    const route = useRoute()

    const app = getCurrentInstance()
    const data = ref([])
    const Id = ref(null)
    const op = ref()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const filter = {
      top: 50,
      totalRecords: 0,
      skip: 0
    }

    const dataForm = ref(null)
    const getDataDetailSchedule = async () => {
      const response = await transScheduleDetailUseCase.getAll({
        id: props.idJadwal,
        search: sendInputSearch.value,
        orderBy: sortingValue.value,
      })
      data.value = response.result
      data.value.map((d) => {
        let y: any = {}
        y = d
        y.AgenString = `${y.Agen.Kode} - ${y.Agen.Nama}`
        y.WaktuKedatanganString = y.WaktuKedatangan ? y.WaktuKedatangan : 'x'
        y.WaktuKeberangkatanString = y.WaktuKeberangkatan ? y.WaktuKeberangkatan : 'x'
        return y
      })
      console.log(data.value)
      store.dispatch('hideLoading')
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const showDropDown = (evt: any, val: any) => {
      dataForm.value = val
      op.value.toggle(evt)
    }
    const editData = (val: any) => {
      dataForm.value = val
      sliderActive.value = true
    }
    const copyData = (val: any) => {
      dataForm.value = val
      sliderActive.value = true
    }
    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }
    const searchData = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getDataDetailSchedule()
    }
    const resetFilter = async () => {
      sendInputSearch.value = ''
      inputSearch.value = ''
      getDataDetailSchedule()
    }
    const deleteData = (val: any) => {
      $confirm.require({
        header: 'Detail Rute',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await transScheduleDetailUseCase.deleteData(val)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: 'Gagal menghapus detail rute, silahkan coba kembali',
              group: 'bc',
              life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            getDataDetailSchedule()
          }
        }
      })
    }
    onMounted(() => {
      getDataDetailSchedule()
      store.subscribeAction((action) => {
        if (action.type === 'updateRute') {
          getDataDetailSchedule()
        }
      })
    })
    return {
      route,
      data,
      inputSearch,
      sendInputSearch,
      searchData,
      filter,
      moment,
      Id,
      showDropDown,
      op,
      sliderActive,
      editData,
      copyData,
      redirectAdd,
      deleteData,
      app,
      store,
      dataForm,
      getDataDetailSchedule,
      closeSidebar,
      resetFilter
    }
  },
}
