import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import moment from 'moment'
import {
  transScheduleDetailUseCase,
  transScheduleBusUseCase
} from '@/domain/usecase'
import store from '@/store'

export default {
  name: 'DetailScheduleForm',
  props: {
    idJadwal: String,
    data: Object
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const attProps = reactive(props)
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const receiptDateOption = ref([])
    const Id = ref(null)
    const agentOption = ref([])
    const dataForm = reactive({
      agent: null,
      receiptDate: null,
      hoursDeparture: null as any,
      hoursArrival: null as any
    })
    const tglHD = ref('')

    const title = ref('Tambah Rute')
    const submitted = ref(false)

    const validHours = (val: any = '') => {
      if (val) {
        if (val.length !== 5 || (val.length === 5 && val.charAt(2) !== ':')) {
          return false
        }
      }
      return true
    }

    const rules = {
      agent: {
        required: helpers.withMessage('Agen Jadwal harus diisi', required)
      },
      receiptDate: {
        required: helpers.withMessage('Tanggal Penerimaan harus diisi', required)
      },
      hoursDeparture: {
        // required: helpers.withMessage('Jam Keberangkatan harus diisi', required)
        // validHours: helpers.withMessage('Jam Keberangkatan tidak valid', validHours),
      },
      hoursArrival: {
        // required: helpers.withMessage('Jam Kedatangan harus diisi', required)
        // validHours: helpers.withMessage('Jam Kedatangan tidak valid', validHours),
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const checkResponse = async (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (attProps.data) {
          $toast.add({
            severity: 'success',
            summary: response.result.title ?? response.result.Title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        emit('reloadData')
        closeSidebar()
      }
    }

    const hoursConvert = (valHours: any) => {
      let hours
      if (valHours.toString().includes(' ')) {
        const arrDate = tglHD.value.split('-')
        hours = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${moment(valHours).format('HH:mm:ss')}+07:00`
      } else {
        const arrDate = tglHD.value.split('-')
        hours = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${valHours}:00+07:00`
      }
      return hours
    }

    const submitProccess = async () => {
      const data = {
        AgenId: dataForm.agent,
        WaktuKeberangkatan: dataForm.hoursDeparture ? hoursConvert(dataForm.hoursDeparture) : null,
        WaktuKedatangan: dataForm.hoursArrival ? hoursConvert(dataForm.hoursArrival) : null,
        TanggalPenerimaanId: dataForm.receiptDate,
        JadwalHdId: Number(attProps.idJadwal)
      }
      const response = await transScheduleDetailUseCase.submitData(Id.value, data)
      checkResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    if (attProps.data) {
      title.value = 'Edit Rute'
      Id.value = attProps.data.Id
      // tglHD.value = moment(attProps.data.JadwalHd.TanggalAktif).format('DD-MM-YYYY')
      dataForm.agent = attProps.data.AgenId
      dataForm.receiptDate = attProps.data.TanggalPenerimaanId
      dataForm.hoursDeparture = attProps.data.WaktuKeberangkatan ? moment(attProps.data.WaktuKeberangkatan).format('HH:mm') : null
      dataForm.hoursArrival = attProps.data.WaktuKedatangan ? moment(attProps.data.WaktuKedatangan).format('HH:mm') : null
    }

    const getOptionAgent = async () => {
      const response = await transScheduleDetailUseCase.getDataOptionAgent()
      agentOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: `${x.Kode} - ${x.Nama}`
      }))
    }

    const getOptionReceiptDate = async () => {
      const response = await transScheduleDetailUseCase.getDataOptionTanggalPenerimaan()
      receiptDateOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: `${x.Kode} - ${x.Nama}`
      }))
    }

    const getDetailSchedule = async () => {
      store.dispatch('showLoading')
      const response = await transScheduleBusUseCase.getDataForm(Number(attProps.idJadwal))
      tglHD.value = moment(response.result.TanggalAktif).format('DD-MM-YYYY')
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      getDetailSchedule()
      getOptionAgent()
      getOptionReceiptDate()
    })
    return {
      attProps,
      app,
      receiptDateOption,
      agentOption,
      Id,
      dataForm,
      title,
      moment,
      submitted,
      rules,
      v$,
      closeSidebar,
      submitProccess,
      submitData,
      getOptionAgent,
      getOptionReceiptDate,
      checkResponse,
      tglHD,
      getDetailSchedule
    }
  }
}
